import axios from "axios";
import _ from "lodash";
import { sleep, TELEMETRY_URL } from ".";

const series_transform = ([k, v]: any) =>
  v.map((x: any) => ({ ts: x.ts, [k]: x.value }));

const series_to_list = (input: any[]) =>
  Object.entries(input).map(series_transform).flat();

const merge = (input: any[]) =>
  Object.entries(_.groupBy(input, "ts")).map(([k, v]: any) =>
    Object.assign({}, ...v)
  );

const convert_output = (input: any) => {
  const items = input?.data ?? [];
  return _.flowRight(merge, series_to_list)(items);
};

export type telemetryRequest = (
  batch: {
    id?: string;
    startTime: number;
    endTime?: number;
    device: { id: string };
  },
  keys: string[],
  headers: any
) => Promise<any[]>;

export const loadMeasurements: telemetryRequest = async (
  input,
  keys,
  headers
) => {
  console.warn("load measurements for batch", input);
  const id = input.device.id ?? input?.id;
  if (id) {
    const url = `${TELEMETRY_URL}/${id}/values/timeseries`;
    await sleep(250);
    const result = await axios.get(url, {
      ...headers,
      params: {
        limit: 100000,
        agg: "NONE",
        orderBy: "DESC",
        useStrictDataTypes: true,
        startTs: input.startTime,
        endTs: input.endTime ?? +new Date(),
        keys: keys.join(","),
      },
    });
    return convert_output(result);
  }
  return [];
};

export const loadTelemetry: telemetryRequest = async (batch, keys, headers) => {
  console.warn("load loadTelemetry for batch", batch);
  if (batch) {
    await sleep(250);
    const url = `${TELEMETRY_URL}/${batch.device.id}/values/timeseries`;
    const result = await axios.get(url, {
      ...headers,
      params: {
        limit: 100000,
        agg: "AVG",
        orderBy: "DESC",
        useStrictDataTypes: true,
        interval: 1000 * 60 * 30,
        startTs: batch.startTime,
        endTs: batch.endTime ?? +new Date(),
        keys: keys.join(","),
      },
    });
    return convert_output(result);
  }
  return [];
};

export type deleteMeasurementType = (
  batch: { device: { id: string } },
  keys: string[],
  ts: number,
  headers: any
) => Promise<void>;

export const deleteMeasurement: deleteMeasurementType = async (
  batch,
  keys,
  ts,
  headers
) => {
  console.warn("deleting ts", ts);
  console.warn("deleting headers", headers);
  const url = `${TELEMETRY_URL}/${batch.device.id}/timeseries/delete`;
  await axios.delete(url, {
    ...headers,
    params: {
      keys: keys.join(","),
      deleteAllDataForKeys: false,
      startTs: ts,
      endTs: ts + 1,
      rewriteLatestIfDeleted: true,
    },
  });
};

export async function setTelemetry({ deviceId, data, headers }: any) {
  console.debug("setTelemetry", { deviceId, data });

  try {
    if (!data) {
      throw {
        message: "The values are missing on the request to Thingsboard.",
      };
    }
    const result = await axios.post(
      `${TELEMETRY_URL}/${deviceId}/timeseries/ANY`,
      data,
      headers
    );
    return result;
  } catch (error) {
    console.error("FAILED TO CALL setTelemetry", error);
    return { error, status: 500 };
  }
}

export const loadRecent = async (device: any, headers: any) => {
  console.warn("load measurements for batch", device);
  await sleep(250);
  const result = await axios.get(
    `${TELEMETRY_URL}/${device.id}/values/timeseries`,
    {
      ...headers,
      params: {},
    }
  );
  return convert_output(result);
};

const telemData = async (id: string, params: any, headers: any) => {
  const url = `${TELEMETRY_URL}/${id}/values/timeseries`;
  await sleep(250);
  return await axios.get(url, {
    ...headers,
    params,
  });
};

export const moveTelemetry = async (
  { from_id, to_id, startTime, endTime, keys }: any,
  headers: any
) => {
  console.warn("move telemetry from ", from_id, " to ", to_id);
  await sleep(250);
  const result = await telemData(
    from_id,
    {
      limit: 100000,
      agg: "NONE",
      orderBy: "DESC",
      useStrictDataTypes: true,
      startTs: startTime,
      endTs: endTime ?? +new Date(),
      keys: keys.join(","),
    },
    headers
  );
  const data = keys
    .map((key: string) => {
      try {
        return result.data[key]?.map(({ ts, value }: any) => ({
          ts,
          values: { [key]: value },
        }));
      } catch (error) {
        return [];
      }
    })
    .flat();
  console.warn("data from device: ", data);
  let k = await setTelemetry({
    deviceId: to_id,
    data,
    headers,
  });
  if (k) console.warn("save response", k.status);
};
