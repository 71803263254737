import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import jwt_decode from "jwt-decode";
import * as Sentry from "@sentry/vue";
import { getAllBatches, getBatches } from "@/components/devices/helpers";
import { sleep } from "@/utils";

import { loadMeasurements, loadTelemetry, loadRecent } from "tb/telemetry";
import { Command } from "@/types";

const AUTH_TOKEN = "apollo-token";
const TB_TOKEN = "tb-token";
Vue.use(Vuex);
Vue.config.devtools = true;
const load = (key: string, value?: any) =>
  JSON.parse(
    window.localStorage.getItem(key) ??
    (value ? JSON.stringify(value) : (null as any))
  );
const save = (key: string, value: any) =>
  window.localStorage.setItem(key, JSON.stringify(value));
save("test_access", "yes");
const stored_test = load("test_access", "no");
const can_store = stored_test == "yes";

window.console.log("can store:", stored_test, can_store);
if (!can_store) {
  alert(
    "App not have permissions to store information in cookies or localstorage, please allow cookies"
  );
}
const api_command = async (command: Command, context: any) => {
  return await axios.post(
    "https://lab.zymoscope.com/api/commands",
    command,
    context.getters.tb_api_headers
  );
};

const loadOktaToken = () => {
  const x = load(AUTH_TOKEN) ?? { expires_in: 0 };
  if (x.expires_in > +new Date()) {
    return x;
  }
  return null;
};

const loadTBToken = (state: any) => {
  const x = load(TB_TOKEN) ?? { expires_in: 0 };
  if (x.expires_in > +new Date()) {
    return x.token;
  } else {
    console.error("your session ended, please login again.");
  }
  return null;
};

const tbLogin = async (credentials: any) =>
  await axios.post("https://fc.zymoscope.com/api/login", credentials);

const default_user = {
  id: {
    entityType: null,
    id: null,
  },
  createdTime: null,
  additionalInfo: {},
  email: null,
  authority: null,
  firstName: null,
  lastName: null,
  name: null,
  ownerId: {},
};

const default_profiles = [
  {
    name: "some test profile",
    definition: "flowrate,duration\n0,2\n10,2",
    dump: [0, 0, 10, 10],
  },
];

export default new Vuex.Store({
  state: {
    api_token: null,
    okta_token: loadOktaToken(),
    batch_loaded: 0,
    user: load("user", default_user),
    loading: false,
    production: true,
    start: 0,
    loader: 0 as any,
    running_batches: [] as any[],
    all_batches: [] as any,
    selected_batches: [] as any,
    settings: {
      yeast: [] as any[],
      malt: [] as any[],
      fermentors: [] as any[]
    },
    status: [{ ts: 0, value: "---" }],
    error: null as any,
    done: false,
    tb_auth: { username: null, password: null },
  },
  getters: {
    tb_api_token(state) {
      state.api_token = loadTBToken(state);
      return state.api_token;
    },
    tb_api_headers(state) {
      return {
        headers: {
          "X-Authorization": loadTBToken(state),
        },
      };
    },
  },
  mutations: {
    update_tb_auth(state, { username, password }) {
      state.tb_auth.password = password;
      state.tb_auth.username = username;
    },
    update_api_token(state, { token, expires_in }) {
      save("api_token", token);
      save(TB_TOKEN, { token, expires_in });
      state.api_token = token;
    },
    update_user(state, payload) {
      window.localStorage.setItem("user", JSON.stringify(payload));
      state.user = payload;
    },
    logout(state, payload) {
      window.localStorage.setItem("user", JSON.stringify(payload));
      state.user = payload;
    },
  },
  actions: {
    async logout(context, payload) {
      console.warn("logout");
      context.state.api_token = null;
      save("api_token", null);
      save(TB_TOKEN, null);
      save("user", null);
      console.warn("logged out");
    },
    async login(context, { username, password }) {
      context.commit("update_tb_auth", { username, password });
      try {
        context.state.loading = true;
        const result = await tbLogin({ username, password });
        context.state.error = null;
        const token = "Bearer " + result?.data?.token.token;
        const decoded: any = jwt_decode(result?.data?.token.token);
        window.console.log(decoded);
        const refresh = result?.data?.token.refreshToken;
        window.console.log(refresh);
        Sentry.setUser({ email: username });
        context.commit("update_user", result?.data.user);
        context.commit("update_api_token", {
          token: token,
          expires_in: decoded.exp * 1000,
        });
        context.dispatch("load_assets");
        context.state.loading = false;
      } catch (error) {
        context.state.loading = false;
        context.state.error = error;
        window.console.error("Error", error);
        alert(
          "Error occured: " +
          ((error as any)?.indexOf("401") > -1 ? "Invalid credential" : error)
        );
        throw error;
      }
    },
    async loadMeasurements(context, payload) {
      let load = async () => {
        try {
          context.state.loading = true;
          for (let batch of context.state.running_batches) {
            if (batch?.devices?.ALICAT != null) {
              if (batch.offset == null) {
                Vue.set(batch, "offset", 0);
              }
              sleep(200);
              let recent = await loadRecent(
                batch?.devices?.ALICAT,
                context.getters.tb_api_headers
              );
              Vue.set(batch.devices.ALICAT, "recent", recent);
              batch.devices.ALICAT = {
                ...batch.devices.ALICAT,
                ...Object.assign({}, ...recent),
                recent,
              };
            }
            if (batch?.devices?.ZYMOMETER != null) {
              sleep(200);
              let recent = await loadRecent(
                batch?.devices?.ZYMOMETER,
                context.getters.tb_api_headers
              );
              batch.devices.ZYMOMETER = {
                ...batch.devices.ZYMOMETER,
                ...Object.assign({}, ...recent),
              };
            }
          }
        } catch (error) {
          console.error("failed reloading measuremtns", error);
        }
        context.state.loading = false;
      };
      let loadTel = async () => {
        try {
          context.state.loading = true;
          for (let batch of context.state.running_batches) {
            if (batch?.devices?.ALICAT != null) {
              if (batch.offset == null) {
                Vue.set(batch, "offset", 0);
              }
              sleep(200);
              let recent = await loadRecent(
                batch?.devices?.ALICAT,
                context.getters.tb_api_headers
              );
              sleep(200);
              let reference = await loadMeasurements(
                { ...batch, device: batch?.devices?.ALICAT },
                [
                  "COMMENT",
                  "BEERFOSS_ABV",
                  "BEERFOSS_ABW",
                  "BEERFOSS_RE_PLATO",
                  "BEERFOSS_AE_PLATO",
                  "BEERFOSS_OE_PLATO",
                  "BEERFOSS_SG",
                  "BEERFOSS_RDF", // Assume this AA?
                  "BEERFOSS_PH",
                  "BEERFOSS_DENSITY",
                  "BEERFOSS_CENTRIFUGED",
                  "SAMPLE_WEIGHT",
                  "author",
                ],
                context.getters.tb_api_headers
              );
              sleep(200);

              let telemetry = await loadTelemetry(
                { ...batch, device: batch?.devices?.ALICAT },
                [
                  "mass_flow",
                  "pressure_abs",
                  "temperature",
                  "net_weight",
                  "totalizer",
                  "flowrate_avg",
                  "SG",
                  "ABV",
                ],
                context.getters.tb_api_headers
              );
              Vue.set(batch.devices.ALICAT, "recent", recent);
              Vue.set(batch.devices.ALICAT, "telemetry", telemetry);
              Vue.set(batch.devices.ALICAT, "reference", reference);
              // batch.devices.ALICAT = { ...batch.devices.ALICAT, ...(Object.assign({}, ...recent)), reference, telemetry }
            }
            if (batch?.devices?.ZYMOMETER != null) {
              sleep(200);
              let recent = await loadRecent(
                batch?.devices?.ZYMOMETER,
                context.getters.tb_api_headers
              );
              batch.devices.ZYMOMETER = {
                ...batch.devices.ZYMOMETER,
                ...Object.assign({}, ...recent),
              };
            }
          }
        } catch (error) {
          console.error("failed reloading measuremtns", error);
        }
        context.state.loading = false;
      };
      let loadTel2 = async () => {
        try {
          context.state.loading = true;
          for (let batch of context.state.selected_batches.filter(
            (x: any) => x?.devices?.ALICAT?.telemetry == null
          )) {
            if (batch?.devices?.ALICAT != null) {
              if (batch.offset == null) {
                Vue.set(batch, "offset", 0);
              }
              sleep(200);
              let recent = await loadRecent(
                batch?.devices?.ALICAT,
                context.getters.tb_api_headers
              );
              sleep(200);
              let reference = await loadMeasurements(
                { ...batch, device: batch?.devices?.ALICAT },
                [
                  "COMMENT",
                  "BEERFOSS_ABV",
                  "BEERFOSS_ABW",
                  "BEERFOSS_RE_PLATO",
                  "BEERFOSS_AE_PLATO",
                  "BEERFOSS_OE_PLATO",
                  "BEERFOSS_SG",
                  "BEERFOSS_RDF", // Assume this AA?
                  "BEERFOSS_PH",
                  "BEERFOSS_DENSITY",
                  "BEERFOSS_CENTRIFUGED",
                  "SAMPLE_WEIGHT",
                  "author",
                ],
                context.getters.tb_api_headers
              );
              sleep(200);

              let telemetry = await loadTelemetry(
                { ...batch, device: batch?.devices?.ALICAT },
                [
                  "flowrate_avg",
                  "pressure_abs",
                  "temperature",
                  "net_weight",
                  "totalizer",
                  "mass_flow",
                  "SG",
                  "ABV",
                ],
                context.getters.tb_api_headers
              );
              Vue.set(batch.devices.ALICAT, "recent", recent);
              Vue.set(batch.devices.ALICAT, "telemetry", telemetry);
              Vue.set(batch.devices.ALICAT, "reference", reference);
              // batch.devices.ALICAT = { ...batch.devices.ALICAT, ...(Object.assign({}, ...recent)), reference, telemetry }
            }
            if (batch?.devices?.ZYMOMETER != null) {
              sleep(200);
              let recent = await loadRecent(
                batch?.devices?.ZYMOMETER,
                context.getters.tb_api_headers
              );
              batch.devices.ZYMOMETER = {
                ...batch.devices.ZYMOMETER,
                ...Object.assign({}, ...recent),
              };
            }
          }
        } catch (error) {
          console.error("failed reloading measuremtns", error);
        }
        context.state.loading = false;
      };
      await load();
      await loadTel();
      await loadTel2();
      context.state.loader = setInterval(load, 20 * 1000);
      //context.state.loader =
      setInterval(loadTel, 300 * 1000);
    },
    async add_malt(context, payload) {
      payload = {
        ...payload,
        created: {
          time: +new Date(),
          by: context.state.user.email,
        }
      }
      const command: Command = {
        command: "add_malt",
        payload
      }
      await api_command(command, context)
    },
    async add_yeast(context, payload) {
      payload = {
        ...payload,
        created: {
          time: +new Date(),
          by: context.state.user.email,
        }
      }
      const command: Command = {
        command: "add_yeast",
        payload
      }
      await api_command(command, context)
    },
    async loadYeast(context, payload) {
      try {
        context.state.loading = true;
        const command: Command = {
          command: "get_yeast", payload
        }
        context.state.settings.yeast =
          await (await api_command(command, context)).data.meta
        console.log("GOT THE YEAST!", context.state.settings.malt)
        context.state.loading = false;
      } catch (error) {
        alert(
          "Error occured while loading yeast from Database. Details: " +
          error
        );
      }
    },
    async loadMalt(context, payload) {
      try {
        context.state.loading = true;
        const command: Command = {
          command: "get_malt", payload
        }
        context.state.settings.malt =
          await (await api_command(command, context)).data.meta
        console.log("GOT THE MALT!", context.state.settings.malt)
        context.state.loading = false;
      } catch (error) {
        alert(
          "Error occured while loading yeast from Database. Details: " +
          error
        );
      }
    },
    async loadBatches(context, payload) {
      try {
        if (+ new Date() - context.state.batch_loaded < 5 * 60 * 1000) return;
        context.state.batch_loaded = +new Date();

        context.state.loading = true;
        context.state.running_batches = await getBatches(
          context.getters.tb_api_headers
        );
        context.state.all_batches = await getAllBatches(
          context.getters.tb_api_headers
        );
        context.dispatch("loadMeasurements");
      } catch (error) {
        alert(
          "Error occured while loading batches from Thingsboard. Details: " +
          error
        );
      }
      context.state.loading = false;
    },
    async loadSelected(context, payload) {
      try {
        context.state.loading = true;
        context.dispatch("loadMeasurements");
      } catch (error) {
        alert(
          "Error occured while loading batches from Thingsboard. Details: " +
          error
        );
      }
      context.state.loading = false;
    },
  },
  modules: {},
});
