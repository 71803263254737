
import Vue from "vue";
// import Logo from "./zymoscope.vue";
import Login from "@/components/login.vue"; // @ is an alias to /src

export default Vue.extend({
  name: "App",
  components: { Login },
  computed: {
    items() {
      if (this.$route.name == "Lab")
        return [
          {
            title: "Workbench",
            icon: "mdi-network",
          },
          { title: "Data", icon: "mdi-cloud-download" },
          { title: "Profiles", icon: "mdi-chart-line" },
        ];
      return [];
    },
  },
  data: () => ({
    links: ["Foss", "AP", "Actions"],
    drawer: false,
  }),
});
