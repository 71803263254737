import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import VueApexCharts from "vue-apexcharts";

Vue.config.productionTip = false;

Vue.use(VueApexCharts);
Vue.component("apex-chart", VueApexCharts);

// Sentry.init({
//   Vue,
//   dsn: "https://d1673d034dd84ce490dbb3ce6cf6c4ba@o1100797.ingest.sentry.io/6129033",
//   integrations: [
//     new Integrations.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ["localhost", "localhost:8080", "lab.zymoscope.com", /^\//],
//     }),
//   ],
//   beforeSend(event, hint) {
//     console.warn('SENTRY:', event, hint)
//     if (event.exception) {
//       Sentry.showReportDialog({
//         eventId: event.event_id, user: {
//           name: store.state.user.name,
//           email: store.state.user.email
//         }
//       });
//     }
//     return event;
//   },
//   tracesSampleRate: 1.0,
// });

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
