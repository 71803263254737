import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Dashboard",
    component: () => import("@/components/dashboard.vue"),
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("@/components/settings.vue"),
  },
  {
    path: "/beerfoss",
    name: "BeerFOSS",
    component: () => import("@/components/devices/beerfoss.vue"),
  },
  {
    path: "/easydens",
    name: "EasyDens",
    component: () => import("@/components/devices/ap-easydens.vue"),
  },
  {
    path: "/dco2",
    name: "DCO2",
    component: () => import("@/components/devices/DCO2.vue"),
  },
  {
    path: "/intervention",
    name: "Intervention",
    component: () => import("@/components/devices/intervention.vue"),
  },
  {
    path: "/control",
    name: "Control",
    component: () => import("@/components/devices/data-input.vue"),
  },
  {
    path: "/compare",
    name: "Compare",
    component: () => import("@/components/compare.vue"),
  },
  {
    path: "/start-batch",
    name: "StartBatch",
    component: () => import("@/components/start-batch.vue"),
  },
  {
    path: "/history",
    name: "History",
    component: () => import("@/components/batch_log.vue"),
  },
  {
    path: "/batch/:batch_id",
    name: "Details",
    component: () => import("../views/Details.vue"),
  },
  {
    path: "/login/callback",
    name: "callback",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Callback.vue"),
  },
  {
    path: "/token",
    name: "token",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Token.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const DEFAULT_TITLE = 'Zymoscope Lab';
router.afterEach((to: any, from) => {
  Vue.nextTick(() => {
    document.title = to.name || DEFAULT_TITLE;
  });
});

export default router;
