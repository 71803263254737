import { sleep } from "@/utils";
import axios from "axios";
import _ from "lodash";


export const endBatch = async (input: any, headers: any) => {
  const result = await axios.post(
    "https://lab.zymoscope.com/api/end-batch", input,
    headers
  );
  return result.data;
}

export const loadData = async (id: string, headers: any) => {
  const result = await axios.post(
    "https://lab.zymoscope.com/api/telemetry-background", { id },
    headers
  );
  return result.status;
}

export const updateBatch = async (id: string, changes: any, headers: any) => {
  const result = await axios.post(
    "https://lab.zymoscope.com/api/update-batch", { id, changes },
    headers
  );
  return result.status;
}

export const loadAllData = async (headers: any) => {
  const result = await axios.post(
    "https://lab.zymoscope.com/api/telemetry-all-background", {},
    headers
  );
  return result.data;
}

export const getBatches = async (headers: any) => {
  const result = await axios.post(
    "https://lab.zymoscope.com/api/industrial-batches", {},
    headers
  );
  console.log("BATCHES:", result.data);
  return result.data.batches;
}

export const getAllBatches = async (headers: any) => {
  const result = await axios.post(
    "https://lab.zymoscope.com/api/industrial-batches", { all: true },
    headers
  );
  console.log("BATCHES:", result.data);
  return result.data.batches;
}

const convert_output = (result: any) => {

  const items = Object.entries(result.data)
    .map(([k, v]: any) =>
      v
        .map((x: any) => ({ ts: x.ts, [k]: x.value }))
    )
    .flat();
  const k = Object.entries(_.groupBy(items, "ts")).map(([k, v]: any) =>
    Object.assign({}, ...v)
  );
  console.log("convert_output", k)
  return k;
}

export const loadConfig = async (headers: any) => {
  return (await axios.get('https://lab.zymoscope.com/api/configuration', headers)).data
}

export const updateBatchNumber = async (numner: number, headers: any) => {
  return (await axios.post('https://lab.zymoscope.com/api/configuration', {
    batch_number: numner
  }, headers)).data
}